var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - Add Organisation" }
      }),
      _c("gov-back-link", { attrs: { to: { name: "organisations-index" } } }, [
        _vm._v("Back to organisations")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "full" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Organisations")
                  ]),
                  !_vm.auth.isSuperAdmin
                    ? [
                        _vm.updateRequestCreated
                          ? _c(
                              "div",
                              [
                                _c(
                                  "gov-heading",
                                  { attrs: { size: "m", tag: "h3" } },
                                  [_vm._v("Create organisation request")]
                                ),
                                _c("gov-body", [
                                  _vm._v(_vm._s(_vm.updateRequestMessage))
                                ]),
                                _c(
                                  "gov-back-link",
                                  {
                                    attrs: {
                                      to: { name: "organisations-index" }
                                    }
                                  },
                                  [_vm._v("Back to organisations")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  !_vm.updateRequestCreated
                    ? [
                        _c("gov-heading", { attrs: { size: "m" } }, [
                          _vm._v("Add organisation")
                        ]),
                        _vm.form.$errors.any()
                          ? _c(
                              "gov-error-summary",
                              { attrs: { title: "Check for errors" } },
                              [
                                _c(
                                  "gov-list",
                                  _vm._l(_vm.form.$errors.all(), function(
                                    error,
                                    field
                                  ) {
                                    return _c("li", {
                                      key: field,
                                      domProps: {
                                        textContent: _vm._s(error[0])
                                      }
                                    })
                                  }),
                                  0
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "gov-tabs",
                          {
                            attrs: { tabs: _vm.tabs, "no-router": "" },
                            on: { "tab-changed": _vm.onTabChange }
                          },
                          [
                            _c(
                              "organisation-tab",
                              {
                                attrs: {
                                  title: "Details",
                                  active: _vm.isTabActive("details")
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "intro",
                                      fn: function() {
                                        return [
                                          _vm._v(
                                            "General details about the organisation. To be found when\n                searching or linked from a service page."
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1260047174
                                )
                              },
                              [
                                _c("organisation-form", {
                                  attrs: {
                                    errors: _vm.form.$errors,
                                    name: _vm.form.name,
                                    slug: _vm.form.slug,
                                    description: _vm.form.description,
                                    url: _vm.form.url,
                                    email: _vm.form.email,
                                    phone: _vm.form.phone,
                                    social_medias: _vm.form.social_medias
                                  },
                                  on: {
                                    "update:name": function($event) {
                                      return _vm.$set(_vm.form, "name", $event)
                                    },
                                    "update:slug": function($event) {
                                      return _vm.$set(_vm.form, "slug", $event)
                                    },
                                    "update:description": function($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "description",
                                        $event
                                      )
                                    },
                                    "update:url": function($event) {
                                      return _vm.$set(_vm.form, "url", $event)
                                    },
                                    "update:email": function($event) {
                                      return _vm.$set(_vm.form, "email", $event)
                                    },
                                    "update:phone": function($event) {
                                      return _vm.$set(_vm.form, "phone", $event)
                                    },
                                    "update:social_medias": function($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "social_medias",
                                        $event
                                      )
                                    },
                                    "update:logo_file_id": function($event) {
                                      _vm.form.logo_file_id = $event
                                    },
                                    clear: function($event) {
                                      return _vm.form.$errors.clear($event)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "organisation-tab",
                              {
                                attrs: {
                                  title: "Taxonomies",
                                  active: _vm.isTabActive("taxonomies")
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "intro",
                                      fn: function() {
                                        return [
                                          _c("gov-body", [
                                            _vm._v(
                                              "\n                  These are a list of ‘tags’ that are applied to an\n                  organisation. These tags help the organisation be found in\n                  categories and keyword searches.\n                "
                                            )
                                          ]),
                                          _c("gov-body", [
                                            _vm._v(
                                              "\n                  On creation of a new organisation, the admin team will\n                  select the tags that they feel represent the organisation.\n                "
                                            )
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  4048993897
                                )
                              },
                              [
                                _c(
                                  "gov-form-group",
                                  {
                                    attrs: {
                                      invalid: _vm.form.$errors.has(
                                        "category_taxonomies"
                                      )
                                    }
                                  },
                                  [
                                    _c("ck-taxonomy-input", {
                                      attrs: {
                                        root: "categories",
                                        value: _vm.form.category_taxonomies,
                                        error: _vm.form.$errors.get(
                                          "category_taxonomies"
                                        )
                                      },
                                      on: {
                                        "update:value": function($event) {
                                          return _vm.$set(
                                            _vm.form,
                                            "category_taxonomies",
                                            $event
                                          )
                                        },
                                        input: function($event) {
                                          return _vm.$emit(
                                            "update:category_taxonomies",
                                            $event
                                          )
                                        },
                                        clear: function($event) {
                                          return _vm.form.$errors.clear($event)
                                        }
                                      }
                                    }),
                                    _vm.form.$errors.has("category_taxonomies")
                                      ? _c("gov-error-message", {
                                          attrs: {
                                            for: _vm.category_taxonomies
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.form.$errors.get(
                                                "category_taxonomies"
                                              )
                                            )
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("gov-section-break", { attrs: { size: "l" } }),
                        _vm.form.$submitting
                          ? _c(
                              "gov-button",
                              { attrs: { disabled: "", type: "submit" } },
                              [_vm._v("Creating...")]
                            )
                          : _c(
                              "gov-button",
                              {
                                attrs: { type: "submit" },
                                on: { click: _vm.onSubmit }
                              },
                              [_vm._v("Create")]
                            ),
                        _vm.form.$errors.any()
                          ? _c("ck-submit-error")
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }